<template>
  <div
    class="mx-1 flex-wrap"
    style="padding: 16px 6px"
  >
    <div class="mr-1">
      <span
        class="font-14"
      >{{ $t('balance.textBalancePlatform', { platformName: isEmediaPlatform ? userName : platformName }) }}</span>
      <feather-icon
        v-b-tooltip.v-light="$t('balance.tooltipCurrentBalance')"
        icon="InfoIcon"
        size="15"
        style="margin-top: -10px; margin-left: 3px"
      />
    </div>
    <div class="d-flex align-items-center justify-content-between">
      <b-spinner
        v-if="loading"
        class="d-block"
        small
      />
      <span
        v-else
        id="user-balance"
        data-testid="user-balance"
        class="font-20 font-weight-bold text-white"
      >
        ${{ userBalance }}
      </span>
      <div>
        <img
          v-if="showIcon && !hasStepGuide"
          v-b-tooltip.hover.top.v-light="!$device.mobile && !$device.ipad ? $t('stepGuideControl.textBalance') : ''"
          id="trigger-add-balance-ecomdy-home"
          :src="require('@/assets/images/common/ic-add-fund-yellow.svg')"
          alt="image"
          class="cursor-pointer ml-1"
          @click="goToAddFund"
        >
        <img
          v-if="showIcon && !hasStepGuide && refundMode"
          v-b-tooltip.hover.top.v-light="!$device.mobile && !$device.ipad ? $t('payment.textRefund') : ''"
          :src="require('@/assets/images/common/ic-refund-yellow.svg')"
          alt="image"
          class="cursor-pointer"
          style="margin-left: 5px"
          @click="$refs['refund-modal'].showModal()"
        >
      </div>
    </div>
    <refund-modal ref="refund-modal" />
  </div>
</template>
<script>
import {
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'

import _get from 'lodash/get'
import RefundModal from '@/views/payment/components/Modal/RefundModal.vue'
import paymentMixin from '@/mixins/paymentMixin'
import authMixin from '@/mixins/authMixin'

const { mapGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersBusinessAccountHistoryLimit } = createNamespacedHelpers('payment')

export default {
  components: {
    RefundModal,
    BSpinner,
    // components
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [numberFormatMixin, stepGuideMixin, paymentMixin, authMixin],
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['user']),
    ...mapGettersBusinessAccountHistoryLimit(['listBusinessAccountHistoryLimit']),

    userBalance() {
      const amount = _get(this.user, ['data', 'balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },

    loading() {
      return _get(this.user, ['loading'], false)
    },

    showIcon() {
      if (this.hasStepGuide && this.isDoneChoosePlan) {
        return this.isDoneUpdateProductLink || this.isDoneAddFund
      }
      return true
    },
  },

  methods: {
    goToAddFund() {
      if (this.noPlan) {
        this.$router.push('/billing')
      } else if (this.isEcomdyPlatform && !this.isExclusivePartner) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },
  },
}
</script>
